import './index.scss';
import $ from 'jquery';
const MAX_STR_LEN = 30;
let search = (v)=>{
	return new Promise((res, rej)=>{
		let qs = ['search='+v];
		qs.push('per_page=5');

		$.get('/wp-json/wp/v2/search?'+qs.join('&')).then((data, status, req)=>{
			let total = req.getResponseHeader('X-WP-Total');
			res({total, data})
		}).catch(rej)
	});
}
let highlight = (str, s)=>{
	if (!str){
		return str;
	}

	let tokens = s.toLowerCase().split(' ').filter(x=>x && x.length > 2);
	return  str.split(' ').filter((x,i)=>x && i<MAX_STR_LEN).map(word=>{
		for (let i=0;i<tokens.length;i++){
			let token = tokens[i]
			let index = word.toLowerCase().indexOf(token);
			if (index > -1){
				return word.substring(0, index)+'<span class="search-highlight">'+word.substring(index, index+token.length)+'</span>'+word.substring(index+token.length)
			}
		}
		return word;
	}).join(' ');

}
$.fn.webfactoryToolbarSearch = function(){
	let $block = $(this);
	let $input = $(this).find('input[name="s"]');
	$block.find('.search-toggler').click(function(){
		$block.toggleClass('is-selected');
		if ($block.hasClass('is-selected')){
			$input[0].focus();
		}
	})
	let $items = $block.find('.search-items');
	let $count = $block.find('.search-total-count');
	$input.on('input', function(){
		let value = $(this).val();
		if (value.length > 2){
			$block.addClass('has-search');
		}
		else{
			$block.removeClass('has-search');

		}
		search(value).then(r=>{
			$count.text(r.total)
			let html = r.data.map(item=>{
			console.log(item)
				return `<a href="${item.url}" class="search-item">
				<h6 class="search-item-title">${highlight(item.title, value)}</h6>
				 <p class="search-item-excerpt">${highlight(item.excerpt, value)}</p>
				</a>`
			}).join('');
			$items.html(html);
		});
	});
}
$(document).ready(function(){

	$('.wp-block-webfactory-search[data-template="toolbar"]').each(function(){
		$(this).webfactoryToolbarSearch();
	})
})